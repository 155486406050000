import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ServicesPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="M/WBE Certified Advertising Photographer and Videographer: Alex Covo Studio NYC " keywords={[`photographer`, `videographer`, `advertising`,  `nyc`,  `fashion-photographer`,  `lifestyle`,  `art`,  `web-development`,  `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">

        <p>
            When we started our professional journey we never imagined the places we would travel to, and the different cultures and experiences
            we have have. Our commitment to excellence and our passion for story telling has entrenched itself in our DNA. We will always treat you
            as our one and only customer and partner. 
          </p>
          <h2 id="Image-is-Everything">
            OUR SERVICES 
          </h2>
          <p><b>ALEX COVO STUDIO NYC</b> is a creative boutique studio specializing in creating engaging visual communications. Our core services are photography and film/video production. 
          We believe that every image should be inspiring. Our clients include small "next-door" businesses to large multinationals.</p>
{/*          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Our Logo is pretty cool. Inspired by the grit and texture of NYC.</figcaption>
          </figure>
          
  */}   

            <p><strong>NYC Certified Minority & Women Owned Business Enterprise</strong></p>
          <p>
            <b>NAIC CODES:</b> 512110 • 541921 • 541922 • 711510<br />
            <b>NIGP CODES:</b> 91501 • 91509 • 91572 • 91578 • 91582 • 91584 • 91596<br />
            <b>PRODUCT SUPPLY CODE:</b> T09 • T010<br />
          </p>
         

          <h2 id="capabilities">CAPABILITIES</h2>
  
         
          <p><b>ALEX COVO STUDIO NYC</b> is a <b>NYC certified minority owned business enterprise</b>. 
          <ul>
          <li>We are a creative boutique studio specializing in creating engaging visual communications.</li>
          <li>We offer the same "big agency" services on any production including location scouts, casting agents, stylists, set and prop designers.</li>
          <li>We are fully insured and handle all production needs including camera, grip, lighting, location, catering, talent and production staff on any assignment.</li>
          <li>We have worked on assignments ranging from Editorials, Ad Campaigns, Lookbooks, ecommerce, and event productions.</li>
          <li>We believe that every image should be inspiring. Our clients include small "next-door" businesses to large multinationals.</li>
          <li>We look forward to collaborating with you on your next city project so you can meet the M/WBE requirements!</li>
          </ul>
          </p>
        

          <h2 id="unordered">
            <strong>
              <strong>WHAT WE DO</strong>
            </strong>
          </h2>
              <h4>Creative Development</h4>
                <ul>
                  <li>Photography and Video Production</li>

                </ul>
              <h4>Producing and Project Management</h4>
                <ul>
                  <li>Local, national and international experience</li>
                  <li>Estimates, budgets, pre-pro/production books, budgets, schedules, usage licensing, insurance</li>
                  <li>Hiring freelancers, photographers, directors, digital techs, location scouts, grip assistants, etc</li>
                  <li>Grip, lighting and digital capture acquisition</li>
                  
                </ul>
             <h4>Photo Production</h4>
                <ul>
                  <li>Creative direction, planning and capture</li>
                  <li>Editing, color correction, distribution</li>
                  <li>Network of freelancers including digital techs, grip assistants, make-up artists, hair stylists, set and prop designers, etc</li>
                  <li>Studio, location, vehicle, grip, lighting and digital capture equipment rentals and acquisition</li>
                  <Link
                  to={`../01-commercial-photography`}
                  title="Drone Services"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More...
                </Link> 
                </ul>   
                 
                <h4>Video Production</h4>
                <ul>
                  <li>Creative development and planning</li>
                  <li>Capture, encoding/transcoding, edit and color, multichannel distribution</li>
                  <li>Network of film crew including directors, camera operators, grip and lighting assistants, etc</li>
                  <li>Camera, grip, lighting acquisition/rentals</li>
                  <Link
                  to={`../05-video`}
                  title="Video Production"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More...
                </Link> 
                </ul>               

 {/*            <h4>Commercial Drone Services</h4>
                <ul>
                  <li>FAA Part 107 Remote Pilot Certified </li>
                  <li>107.29 daylight operations waiver</li>
                  <li>Operations, safety and procedures documentatio - We filed over 50 successful 107.41 Wide Area Authorizations for use of controlled airspace nationally</li>
                  <li>Aerial cinematography - photo and video</li>
                  <li>Real estate marketing - Creating breathtaking marketing deliverables</li>
                  <li>Industrial applications</li>
                  <Link
                  to={`../06-aerial-drone-services`}
                  title="Drone Services"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More...
                </Link> 
                </ul>
  */}
              <h4>Web Development</h4>
                <ul>
                  <li>We have a deep understanding of web development, interactive design, content management systems, digital asset management, performance metrics, and rich media integration and delivery</li>
                
                  <li>Some of our favorite Jamstack tools we are curretly using include GatsbyJS, Cloudinary, GraphQL, Cloudfare, Netlify and Github</li>
                  <Link
                  to={`../07-web-development`}
                  title="Web Development"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More...
                </Link> 
                </ul>

          <h2 id="Image-is-Everything">
            WHY US? 
          </h2>
          <p>You are more than a client. You are our partner. We take ownership of every project and will not rest until we have succeeeded in delivering the best service available. Why Us?</p>
          <ul>
            <li>You have direct access to the CEO/Owner</li>
            <li>The team that pitches your ideas is the team that actually works on your project.</li>
            <li>A personal touch, because your working with a carefully chosen team, specifically tailored to your needs.</li>
            <li>Lower costs, because we don’t nee the larger overhead of a bigger firm. So those costs aren’t being passed on to you.</li>
            <li>No project is too large or small. While true, we also don’t take on more projects then we can comfortably handle at one time</li>
          </ul>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "alexcovo-logo.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ServicesPage location={props.location} data={data} {...props} />
    )}
  />
)
